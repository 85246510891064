// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBpVxHFxhvgpvh1j50PzpQ704e2o1Gyib4",
  authDomain: "bepass-platform.firebaseapp.com",
  databaseURL: "https://bepass-platform-default-rtdb.firebaseio.com",
  projectId: "bepass-platform",
  storageBucket: "bepass-platform.appspot.com",
  messagingSenderId: "563791290358",
  appId: "1:563791290358:web:44193d43df4549a5caf1d7",
  measurementId: "G-3B8Q36W2S0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const appSet = getAuth(app);

const sessionItem:any = window.sessionStorage.getItem('user-key')
const { tenant_id} = sessionItem ? JSON.parse(sessionItem): {tenant_id: 'fluminense-xt4im'}

appSet.tenantId = tenant_id
export const auth  = appSet;

